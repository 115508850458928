@import '../../../sass/abstracts'

.sectionModalMiTelmex
    width: 100%
    border: 1px solid #e9e9e9
    //box-shadow: -2px 15px 13px rgba(0, 0, 0, 0.11)
    border-radius: 10px
    margin-top: 10px
    margin-bottom: 10px
    text-align: center
    text-decoration: none
    color: #000
    position: relative
    display: flex
    flex-wrap: wrap
    align-items: center
    justify-content: center
    padding: 5px 20px
    box-sizing: border-box
    .is-dark-mode &
        background: var(--color-background-middle-dm)
        border: none
        box-shadow: none
        color: white
    @media only screen and (max-width: 1024px)
        padding: 5px 20px 15px
        max-width: calc(100% - 30px)
        margin: 15px 15px
    &.isLoged
        @media only screen and (max-width: 600px)
            justify-content: space-around
            padding: 10px 20px
            margin: 5px 10px 10px
            max-width: calc(100% - 20px)
        &:after
            content: ''
            position: absolute
            right: 20px
            top: 28px
            width: 10px
            height: 10px
            display: block
            border-top: 2px solid
            border-right: 2px solid
            transform: rotate(45deg)
            @media only screen and (max-width: 600px)
                top: 18px
        .sectionLogos
            @media only screen and (max-width: 830px)
                margin: 5px 20px
            @media only screen and (max-width: 600px)
                justify-content: flex-start
                margin: 5px
            .span
                @media only screen and (max-width: 600px)
                    margin: 0 10px
            .icoClaro
                min-width: 160px
                height: 35px
                @media only screen and (max-width: 600px)
                    height: 18px
                    min-width: 70px
            .icoTelmex
                min-width: 60px
                height: 35px
                @media only screen and (max-width: 600px)
                    min-width: 35px
                    height: 20px
        .sectionTextosSaldo
            display: flex
            align-items: center
            flex-direction: row
            @media only screen and (max-width: 830px)
                flex-direction: column
            .textCredit
                margin: 0 20px
                @media only screen and (max-width: 600px)
                    margin: 0
                    font-size: 13px
            .colorTelmex
                @media only screen and (max-width: 600px)
                    font-size: 16px
        .sectionLogos,
        .sectionTextosSaldo
            @media only screen and (max-width: 600px)
                max-width: 50%

    .sectionClose
        position: absolute
        right: 5px
        top: 5px
        .closeTelmex
            width: 35px
            height: 30px
            border: none
            background: none
            outline: none
            &::before
                content: ""
                background-repeat: no-repeat
                background-size: contain
                background: url(/img/close3.png)
                width: 20px
                height: 20px
                display: block
                cursor: pointer
                .is-dark-mode &
                    filter: invert(1)
    
    .sectionTextosSaldo
        margin: 0 10px
        font-size: 34px
        font-weight: 700
        .textCredit
            font-weight: 400
            font-size: 16px

    .colorTelmex
        color: #1485c1 !important
    .sectionTextos
        max-width: 290px
        display: flex
        flex-direction: row
        @media only screen and (max-width: 450px)
            flex-direction: column
        p
            margin: 0 5px
            span
                font-weight: 700

    .sectionLogos
        max-width: 370px
        display: flex
        align-items: center
        margin: 10px 20px
        .creditoParrafo
            width: 40%
            float: right
            position: relative
            text-align: right
            p
                @media only screen and (max-width: 450px)
                    font-size: 14px
        .span
            border-left: 1px solid #8f8f8f
            height: 50px
            width: 1px
            margin: 0 20px
            @media only screen and (max-width: 1121px)
                height: 20px
        .icoClaro,
        .icoTelmex
            max-width: 220px
            min-width: 120px
            height: 45px
            background-repeat: no-repeat
            background-size: contain
            background-position: center
            display: block
            @media only screen and (max-width: 650px)
                height: 35px
            img
                width: 100%
                height: auto
                display: none
        .icoClaro
            background-image: url(/img/claroshop.svg)
            min-width: 220px
            @media only screen and (max-width: 1121px)
                min-width: 160px
            @media only screen and (max-width: 650px)
                min-width: 110px
            .is-dark-mode &
                background-image: url(/img/claroshop_DM.svg)

        .icoTelmex
            background-image: url(/img/telmexColor.svg)
            min-width: 100px
            @media only screen and (max-width: 1121px)
                min-width: 60px
            @media only screen and (max-width: 650px)
                min-width: 40px
            .is-dark-mode &
                background-image: url(/img/telmexWhite.svg)


    .sectionButton
        @media only screen and (max-width: 650px)
            min-width: 100%
        .btn-credito-disponible
            min-width: 290px
            margin: 0 10px
            @media only screen and (max-width: 450px)
                min-width: 220px
                margin: 0
