.modalFiltroPedido
    z-index: 100
    .contenidoFiltroPedido
        background: white
        padding: 20px 0px !important
        .dataModal
            margin-top: 40px !important
    .seccionSuperiorFiltroPedido
        display: flex
        // border-bottom: 1px solid var(--color-gris-borde)
        box-shadow: 0 4px 2px -2px rgba(0,0,0,0.10)
        background: white
        width: 100%
        .contentCloseFiltroPedido
            display: flex
            justify-content: space-between
            flex-direction: row
            align-items: center
            padding: 10px 10px
            margin: -20px 0px 0px
            box-sizing: border-box
            width: 100%
            position: sticky
            top: -20px
            background: white
            z-index: 1
            .aplicarFiltro
                color: var(--color-rojo-pale)
                cursor: pointer
    .iteracionFiltroPedido
        margin-bottom: 10px
        margin-top: 10px
        .titleFilter
            padding: 10px 10px
            border-bottom: 1px solid rgba(0,0,0,0.1)
        .ulFiltroFecha
            border-radius: 5px
            border: 1px solid rgba(0,0,0,0.1)
            width: 90%
            margin-left: auto
            margin-right: auto
            li
                padding: 5px
    .botonFiltrarPedido
        display: flex
        flex-direction: row
        align-items: center
        // margin: -20px -14px 0
        box-sizing: border-box
        width: 80%
        background: white
        z-index: 1
        padding: 10px
        margin-left: auto
        margin-right: auto
        button
            &.bFiltrar
                margin-top: 1%
                width: 96%
                margin-left: 2%
                box-sizing: border-box
                display: flex
                flex-direction: row
                align-items: center
                justify-content: center
                border-radius: 25px
                margin-bottom: 10px
                background: var(--color-rojo-pale)
                color: #fff
                font-size: 13px
                font-weight: 400
                text-decoration: none
                padding: 8px 16px
                cursor: pointer
                height: 40px
                border: 1px solid rgba(0,0,0,0)
