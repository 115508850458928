@import '../../sass/_abstracts'

$turquesa: #2591B4

.contenedorFiltros
    .titulo
        display: flex
        padding-bottom: 10%
        .h2
            font-weight: 600
            font-size: 16px
            padding-top: 16%
            width: 50%
            +mediaMax780
                text-align: left
        p
            text-align: right
            text-decoration: underline
            padding-top: 16%
            width: 50%
            cursor: pointer
            display: flex
            justify-content: flex-end
            &::before
                content: ' '
                width: 22px
                height: 22px
                background: url(/img/icon-eliminar-blue.svg) no-repeat !important
                cursor: pointer
                display: block
                margin-right: 15px
    .seccionOrdenar
        background: white
        width: 98%
        padding-bottom: 10px
        .titulo
            text-align: left
            padding-bottom: 10px
            h4
                margin-left: 10px
                color: black// #626262
                font-size: 16px
                font-weight: 700
        .is-dark-mode &
            background: transparent
            .titulo
                h4
                    color: var(--color-text-placeholder-dm)
        .pushOrden
            width: 100%
            display: flex
            margin-left: 1%
            margin-top: 1%
            margin-bottom: 1%
            font-size: 13px
            span
                border-radius: 20px
                padding: 2px 4px//6px 5px 7px 5px
                margin-right: 7px
                cursor: pointer
                display: flex
                align-items: center
                +mediaMax480
                    padding: 5px
                    font-size: 13px
                +mediaMax320
                    padding: 5px
                    font-size: 12px
                &.push
                    border: 1px solid #808080
                    color: #808080
                .is-dark-mode &
                    background: white
                &.activar
                    border: 1px solid red
                    color: white
                    background: var(--color-rojo-pale)
    .sinBorde
        border-top: none !important
    .seccionEstrellas
        padding-top: 5%
        padding-bottom: 5%
        margin-top: 2%
        width: 100%
        border-top: 1px solid var(--color-gris-borde)
        +mediaMax780
            margin-top: 2%
        +mediaMax480
            margin-top: 3%
        .cajaEstrellas
            display: flex
            width: 100%
            .predeterminada
                width: 90%
                text-align: left
            .estrellas
                width: 82%
                text-align: left
                margin-left: 1px
                display: flex
                align-items: center
                +mediaMax960
                    margin-top: 1%
                    margin-bottom: 1%
                +mediaMax780
                    padding-top: 1%
                    padding-bottom: 2%
                +mediaMax480
                    width: 84%
                +mediaMax320
                    width: 86%
                p
                    color: #000
                    font-weight: 500
                    padding-left: 5px
                span.start
                    &:before
                        content: "\f005"//"\f054"//
                        font-family: FontAwesome
                        font-weight: 700
                        padding: 1px
                        color: #bdbdbd
                        font-size: 20px
                        +mediaMax320
                            font-size: 18px
                span.startYellow
                    &:before
                        content: "\f005"
                        font-family: FontAwesome
                        font-weight: 700
                        padding: 1px
                        color: var(--color-gold)//#fec600
                        filter: sepia(100%) hue-rotate(0deg) saturate(400%)
                        font-size: 15px

            .cajaSwitch
                width: 10%
                +mediaMax960
                    margin-top: 2%
                    margin-bottom: 2%
                +mediaMax780
                    padding-top: 2%
                    padding-bottom: 1%
                    position: relative
                    left: -2px
                label
                    pointer-events: none
                    .input
                        display: none
                        &:checked + .toggle
                            box-shadow: 0 8px 14px 0 rgba(darken(#3957ee, 12%), 0.12)
                            background: var(--color-rojo-pale)

                        &:checked + .toggle > .selector
                            left: calc(100% - 18px)
                            background-color: #fff
                    .toggle
                        position: relative
                        width: 40px
                        height: 18px
                        background-color: #red
                        border-radius: 999px
                        margin: auto
                        cursor: pointer
                        pointer-events: all
                        box-shadow: 0 8px 14px 0 rgba(darken(#ee5f39, 12%), 0.12)
                        background: #c5d0de
                        .selector
                            width: 15px
                            height: 15px
                            position: absolute
                            top: 50%
                            left: 2px
                            transform: translateY(-53%)
                            background-color: #fff
                            transition: left 0.25s ease
                            border-radius: 50%
                .switch
                    position: relative
                    display: inline-block
                    width: 60px
                    height: 28px
                    input
                        opacity: 0
                        width: 0
                        height: 0
                .slider
                    position: absolute
                    cursor: pointer
                    top: 0
                    left: 0
                    right: 0
                    bottom: 0
                    background-color: #ccc
                    -webkit-transition: .4s
                    transition: .4s
                    &:before
                        position: absolute
                        content: ""
                        height: 20px
                        width: 20px
                        left: 4px
                        bottom: 4px
                        background-color: white
                        -webkit-transition: .4s
                        transition: .4s
                    &.round
                        border-radius: 34px
                        &:before
                            border-radius: 50%
                input:checked + .slider
                    background-color: var(--color-rojo-pale)
                input:focus + .slider
                    box-shadow: 0 0 1px #b3b3b3
                input:checked + .slider:before
                    -webkit-transform: translateX(26px)
                    -ms-transform: translateX(26px)
                    transform: translateX(26px)
    .seccionFulfillment
        margin-top: 2%
        align-items: center
        padding-top: 12px
        border-top: 1px solid var(--color-gris-borde)
        +mediaMax480
            margin-top: 3%
        .cajaFulfillment
            display: flex
            margin-left: 2%
            align-items: center
            +mediaMax960
                margin-top: 2%
                margin-bottom: 2%
            +mediaMax780
                margin-top: 2%
                margin-bottom: 2%
            +mediaMax480
                margin-right: 2%
            .cajaTexto
                width: 80%
                text-align: left
                color: #626262
                .express
                    width: 100px
                +mediaMax480
                    width: 83%
                +mediaMax320
                    width: 85%
            .cajaSwitch
                width: 10%
                +mediaMax780
                    padding-top: 2%
                    padding-bottom: 1%
                .switch
                    position: relative
                    display: inline-block
                    width: 60px
                    height: 28px
                    input
                        opacity: 0
                        width: 0
                        height: 0
                    .slider
                        position: absolute
                        cursor: pointer
                        top: 0
                        left: 0
                        right: 0
                        bottom: 0
                        background-color: #ccc
                        -webkit-transition: .4s
                        transition: .4s
                        &:before
                            position: absolute
                            content: ""
                            height: 20px
                            width: 20px
                            left: 4px
                            bottom: 4px
                            background-color: white
                            -webkit-transition: .4s
                            transition: .4s
                        &.round
                            border-radius: 34px
                            &:before
                                border-radius: 50%
                    input:checked + .slider
                        background-color: #096//#fb0404 // #2196F3
                    input:focus + .slider
                        box-shadow: 0 0 1px #b3b3b3
                    input:checked + .slider:before
                        -webkit-transform: translateX(26px)
                        -ms-transform: translateX(26px)
                        transform: translateX(26px)
            label
                pointer-events: none
                .input
                    display: none
                    &:checked + .toggle
                        box-shadow: 0 8px 14px 0 rgba(darken(#3957ee, 12%), 0.12)
                        background: rgb(48,206,168)
                    &:checked + .toggle > .selector
                        left: calc(100% - 18px)
                        background-color: #fff
                .toggle
                    position: relative
                    width: 40px
                    height: 18px
                    background-color: #red
                    border-radius: 999px
                    margin: auto
                    cursor: pointer
                    pointer-events: all
                    box-shadow: 0 8px 14px 0 rgba(darken(#096, 12%), 0.12)
                    background: #c5d0de
                    .selector
                        width: 15px
                        height: 15px
                        position: absolute
                        top: 50%
                        left: 2px
                        transform: translateY(-53%)
                        background-color: #fff
                        transition: left 0.25s ease
                        border-radius: 50%
    .seccionEnvioFiltros
        align-items: center
        padding-top: 15px
        padding-bottom: 15px
        border-top: 1px solid var(--color-gris-borde)
        +mediaMax480
            margin-top: 3%
        .cajaEnvio
            display: flex
            margin-left: 2%
            align-items: center
            +mediaMax480
                margin-right: 2%
                margin-top: 2%
            .cajaTexto
                width: 80%
                text-align: left
                color: #009966
                font-weight: 500
                p
                    font-size: 12px
                    display: flex
                    align-items: center
                    font-weight: 500
                    &::before
                        content: ''
                        width: 22px
                        height: 18px
                        background: url(/img/camion-de-reparto.svg) no-repeat !important
                        cursor: pointer
                        display: block
                        padding-right: 10px
                .is-dark-mode &
                    color: var(--color-text-placeholder-dm)
                +mediaMax960
                    margin-top: 2%
                    margin-bottom: 2%
                +mediaMax780
                    padding-bottom: 2%
                    padding-top: 2%
                +mediaMax480
                    width: 83%
                +mediaMax320
                    width: 85%
            .cajaSwitch
                width: 10%
                +mediaMax780
                    padding-top: 2%
                    padding-bottom: 1%
                .switch
                    position: relative
                    display: inline-block
                    width: 60px
                    height: 28px
                    input
                        opacity: 0
                        width: 0
                        height: 0
                    .slider
                        position: absolute
                        cursor: pointer
                        top: 0
                        left: 0
                        right: 0
                        bottom: 0
                        background-color: #ccc
                        -webkit-transition: .4s
                        transition: .4s
                        &:before
                            position: absolute
                            content: ""
                            height: 20px
                            width: 20px
                            left: 4px
                            bottom: 4px
                            background-color: white
                            -webkit-transition: .4s
                            transition: .4s
                        &.round
                            border-radius: 34px
                            &:before
                                border-radius: 50%
                    input:checked + .slider
                        background-color: var(--color-rojo-pale)
                    input:focus + .slider
                        box-shadow: 0 0 1px #b3b3b3
                    input:checked + .slider:before
                        -webkit-transform: translateX(26px)
                        -ms-transform: translateX(26px)
                        transform: translateX(26px)
            label
                pointer-events: none
                .input
                    display: none
                    &:checked + .toggle
                        box-shadow: 0 8px 14px 0 rgba(darken(#3957ee, 12%), 0.12)
                        background: rgb(48,206,168)

                    &:checked + .toggle > .selector
                        left: calc(100% - 18px)
                        background-color: white
                .toggle
                    position: relative
                    width: 40px
                    height: 18px
                    background-color: var(--color-rojo-pale)
                    border-radius: 999px
                    margin: auto
                    cursor: pointer
                    pointer-events: all
                    box-shadow: 0 8px 14px 0 rgba(darken(#ee5f39, 12%), 0.12)
                    background: #c5d0de
                    .selector
                        width: 15px
                        height: 15px
                        position: absolute
                        top: 50%
                        left: 2px
                        transform: translateY(-53%)
                        background-color: #fff
                        transition: left 0.25s ease
                        border-radius: 50%
    .seccionPrecio
        align-items: center
        margin-left: 5px
        padding-bottom: 30px
        padding-top: 20px
        +mediaMax780
            padding-top: 10px
        +mediaMax480
            padding-top: 10px

        .texto
            text-align: left
            color: #000
            font-weight: 700
            .is-dark-mode &
                color: var(--color-text-placeholder-dm)
        .precios
            margin-top: 2%
            text-align: left
            display: flex
            label
                position: relative
                color: #939393
                font-weight: 500
                margin-left: 5px
                // display: none
            input
                border-left: transparent
                border-right: transparent
                border-top: transparent
                border-bottom: 1px solid rgba(0,0,0,0.20)
                margin-right: 3%
                appearance: none
                outline: none
                text-align: center
                width: 55%
                +mediaMax480
                    width: 35%
                &:focus,
                &:active
                    outline-offset: 0px
                    border-left: transparent
                    border-right: transparent
                    border-top: transparent
                &::-webkit-input-placeholder
                    text-align: center
                    line-height: 100px
    .botonFiltrarDes
        padding: 0px 20px
        width: calc(100% - 5px)
        background: white
        height: 70px
        align-content: center
        align-items: center
        left: -13px
        position: relative
        .is-dark-mode &
            background: transparent
        button.bFiltrar
            margin-top: 1%
            width: 100%
            box-sizing: border-box
            display: flex
            flex-direction: row
            align-items: center
            justify-content: center
            border-radius: 6px
            margin-bottom: 10px
            background: var(--color-rojo-pale)
            color: #fff
            font-size: 16px
            font-weight: 500
            text-decoration: none
            padding: 8px 16px
            cursor: pointer
            height: 40px
            border: 1px solid rgba(0,0,0,0)
            outline: none
    .contentModal
        border-top: 1px solid var(--color-gris-borde)
        border-bottom: 1px solid var(--color-gris-borde)

        .seccionSuperior
            padding-top: 5%
            h2, .h2
                font-weight: 700
                text-align: left
                padding-left: 10px
                font-size: 16px
        .iteracion
            padding-bottom: 3%
            padding-top: 2%
            ul
                li
                    padding: 10px 6px 10px 6px
                    text-align: left
                    margin-left: 1%
                    label
                        cursor: pointer
                    .radio
                        float: right
                        width: 15px
                        border-color: transparent
                        visibility: visible//hidden
                        height: 14px
                    input[type='radio']:after
                        width: 13px
                        height: 13px
                        border-radius: 15px
                        top: -1px
                        left: 0px
                        position: relative
                        content: ''
                        display: inline-block
                        visibility: visible
                        // border: 1px solid red
                    input[type='radio']:checked:after
                        width: 13px
                        height: 13px
                        border-radius: 15px
                        top: -1px
                        left: -1px
                        position: relative
                        background-color: var(--color-rojo-pale)
                        content: ''
                        display: inline-block
                        visibility: visible
                        border: 2px solid white
    .contentModalCategoria
        border-top: 1px solid var(--color-gris-borde)
        .seccionSuperior
            padding-top: 5%
            h2, .h2
                font-weight: 700
                text-align: left
                padding-left: 10px
                font-size: 16px
        .iteracion
            padding: 10px 0 20px
            // height: 100%
            // max-height: 255px
            // overflow-x: hidden
            // overflow-y: auto
            // margin-bottom: 10px
            ul
                max-height: 490px
                overflow: auto
                li
                    padding: 10px 6px 10px 6px
                    text-align: left
                    margin-left: 1%
                    label
                        cursor: pointer
                    .radio
                        float: right
                        width: 15px
                        border-color: transparent
                        visibility: visible//hidden
                        height: 14px
                    input[type='radio']:after
                        width: 13px
                        height: 13px
                        border-radius: 15px
                        top: -1px
                        left: 0px
                        position: relative
                        content: ''
                        display: inline-block
                        visibility: visible
                        // border: 1px solid red
                    input[type='radio']:checked:after
                        width: 13px
                        height: 13px
                        border-radius: 15px
                        top: -1px
                        left: -1px
                        position: relative
                        background-color: var(--color-rojo-pale)
                        content: ''
                        display: inline-block
                        visibility: visible
                        border: 2px solid white
            .boxVerMasCategorias
                text-align: left
                padding-top: 2%
                padding-bottom: 1%
                padding-left: 3%
                .textoVerMas
                    text-decoration: underline
                    color: $turquesa
                    cursor: pointer
                .textoVerMenos
                    text-decoration: underline
                    color: $turquesa
                    cursor: pointer

    .contentModalMarca
        border-top: 1px solid var(--color-gris-borde)
        .seccionSuperior
            padding-top: 5%
            h2, .h2
                font-weight: 700
                text-align: left
                padding-left: 10px
                font-size: 16px
        .iteracion
            padding: 10px 0 20px
            ul
                max-height: 490px
                overflow: auto
                li
                    padding: 10px 6px 10px 6px
                    text-align: left
                    margin-left: 1%
                    label
                        cursor: pointer
                    .radio
                        float: right
                        width: 15px
                        border-color: transparent
                        visibility: visible
                        height: 14px
                    input[type='radio']:after
                        width: 13px
                        height: 13px
                        border-radius: 15px
                        top: -1px
                        left: 0px
                        position: relative
                        content: ''
                        display: inline-block
                        visibility: visible
                        // border: 1px solid red
                    input[type='radio']:checked:after
                        width: 13px
                        height: 13px
                        border-radius: 15px
                        top: -1px
                        left: -1px
                        position: relative
                        background-color: var(--color-rojo-pale)
                        content: ''
                        display: inline-block
                        visibility: visible
                        border: 2px solid white
            .boxVerMasMarcas
                text-align: left
                padding-top: 2%
                padding-bottom: 1%
                padding-left: 3%
                .textoVerMas
                    text-decoration: underline
                    color: $turquesa
                    cursor: pointer
                .textoVerMenos
                    text-decoration: underline
                    color: $turquesa
                    cursor: pointer

.contProductCategoryDesk
    padding: 0
.contenedorFiltros
    .titulo
        padding: 20px 0
        box-sizing: border-box
        .h2
            color: var(--color-rojo-pale)
        .h2,p
            padding: 0
.barraResultados
    padding: 20px 0 1px!important
    box-sizing: border-box
    .countResults,
    .boxVistasDesktop
        // padding: 0!important

.seccionFulfillment,
.seccionEnvioFiltros,
.seccionEstrellas
    align-items: center
    border-top: 1px solid var(--color-gris-borde)
    padding: 10px 5px
    box-sizing: border-box
    margin-top: 0!important
    min-height: 50px
    .cajaFulfillment,
    .cajaEnvio,
    .cajaEstrellas
        display: flex
        width: 100%
        margin-left: 0
        align-items: center

.barraResultados
    display: flex
    flex-direction: row
    align-items: center
    justify-content: flex-end
    width: 99%
    padding: 0 5px
    box-sizing: border-box
    font-size: 12px
    background-color: white
    +mediaMax780
        width: 96%
        display: grid
        grid-template-columns: repeat(3,1fr)
        justify-content: center
        margin-left: auto
        margin-right: auto
    +mediaMax480
        display: grid
        grid-template-columns: repeat(2,1fr)
    .is-dark-mode &
        background-color: var(--color-background-dm)
        border-bottom: 1px solid #666
        color: white
        padding: 20px 15px!important
    .countResults
        box-sizing: border-box
        padding: 10px 5px 10px 5px
        width: 120px
        align-content: center
        align-items: center
        text-align: left
        justify-content: center
        color: #8c8c8c
        +mediaMax480
            width: 98%
    .boxVistasDesktop
        display: flex
        padding: 12px 5px 12px 5px
        cursor: pointer
        width: 110px
        align-items: center
        color: #8c8c8c
        border: 1px solid rgba(0,0,0,0.2)
        border-radius: 10px
        font-size: 14px
        +mediaMax480
            width: 60%
        .vistaListaDesktop
            padding: 0 5px 0 10px
            color: black
            &::after
                content: ' '
                width: 20px
                height: 16px
                background: url(/img/lista.png) no-repeat
                cursor: pointer
                display: block
                padding-right: 5px
        .vistaMosaicoDesktop
            padding: 0 5px
            color: black
            &::after
                content: ' '
                width: 20px
                height: 16px
                background: url(/img/mosaico15px.png) no-repeat
                cursor: pointer
                display: block
                padding-right: 5px
        .vistaMosaicoDesktop
            &.activeVista
                &::after
                    background: url(/img/mosaico15pxrojo.png) no-repeat
        .vistaListaDesktop
            &.activeVista
                &::after
                    background: url(/img/listarojo.png) no-repeat

.contenedorOrdenar
    width: 200px
    +mediaMax780
        width: 100%
    +mediaMax480
        width: 98%
    .seccionOrdenarDesktop
        display: flex
        align-items: center
        float: right
        cursor: pointer
        +mediaMax780
            float: none
        .titulo
            padding-right: 5px
        .boxSelectOrden
            position: relative!important
            font-size: .875rem
            color: #666
            border: 1px solid rgba(0,0,0,0.20)
            border-radius: 10px
            padding: 7px 5px
            min-width: 165px
            background: #fff url(/img/ic-arrow-red.svg) no-repeat 90% center
            cursor: pointer
            span
                position: relative
            // border: 1px solid #ccc
            // width: 140px
            // overflow: hidden
            // background: #fff url(/img/r-arrow20px.png) no-repeat 90% center
            // border-radius: 10px
            // padding: 0 40px 0 0px
            // cursor: pointer
            // height: 40px
            // display: flex
            // align-items: center
            // .is-dark-mode &
            //     background-color: #383838
            //     border-color: #888888
            select
                -webkit-appearance: none
                -moz-appearance: none
                appearance: none
                background: 0 0
                border: 0
                width: 95%
                color: #8c8c8c
                // margin-top: 6px
                border-radius: initial
                padding: 6px !important//4px!important
                font-weight: 300
                // padding: 5px 8px
                // width: 130%
                // border: none
                // box-shadow: none
                // background-color: transparent
                // background-image: none
                // appearance: none
                // cursor: pointer
                &:after
                    -moz-box-sizing: border-box
                    -webkit-box-sizing: border-box
                    box-sizing: border-box
                    content: ">"//""
                    font-family: FontAwesome
                    -webkit-transform: translateY(-50%)
                    -moz-transform: translateY(-50%)
                    -ms-transform: translateY(-50%)
                    transform: translateY(-50%)
                    top: 50%
                    position: absolute
                    right: 0
                    border-left: 1px solid #ededed
                    padding: 9px 10px
                    pointer-events: none
                .is-dark-mode &
                    color: white
                &:focus
                    outline: none
            option
                &:after
                    border-left: none
                .is-dark-mode &
                    color: white
                    background-color: #383838
.borderTop
    border-top: 1px solid var(--color-gris-borde)

.sliderPrecioDesk
    margin-bottom: 20px
    width: 88%
    margin-top: 15px
    margin-left: auto
    margin-right: auto
    & label
        color: #888
        font-size: 0.8rem
        margin-bottom: 20px
        display: block
        text-transform: capitalize
    .input-range
        height: 1rem
        position: relative
        width: 100%
        .input-range__label--min,
        .input-range__label--max
            bottom: -1.4rem
            position: absolute
        .input-range__label--min
            left: 0
        .input-range__label--max
            right: 0

        .input-range__label
            color: #aaaaaa
            font-family: "Helvetica Neue", san-serif
            font-size: 0.8rem
            transform: translateZ(0)
            white-space: nowrap
        .input-range__track--background
            left: 0
            margin-top: -0.15rem
            position: absolute
            right: 0
            top: 50%
        .input-range__track
            background: #2590b438
            border-radius: 0.3rem
            cursor: pointer
            display: block
            height: 0.18rem
            position: relative
            transition: left 0.3s ease-out, width 0.3s ease-out
        .input-range__track--active
            background: #2591b4
        .input-range__slider-container
            transition: left 0.3s ease-out
        .input-range__label--value
            position: absolute
            top: -1.8rem
        .input-range__label--value .input-range__label-container
            background: #2591b4
            color: white
            font-size: 0.65rem
            padding: 2px 5px
            border-radius: 15px
            display: none
        .input-range__label-container
            left: -50%
            position: relative
            display: none
        .input-range__slider
            appearance: none
            background: #2591b4
            border: 1px solid #2591b4
            border-radius: 100%
            cursor: pointer
            display: block
            height: 1rem
            margin-left: -0.5rem
            margin-top: -0.65rem
            outline: none
            position: absolute
            top: 50%
            transition: transform 0.3s ease-out, box-shadow 0.3s ease-out
            width: 1rem
