@import '../../sass/_abstracts'

#modal-newsletter 
    white-space: nowrap
    overflow: auto
    margin-top: 5%
    +mediaMax480
        white-space: initial
        overflow: auto
        text-align: center
        margin-top: 9%
    .closeNWL
        width: 80px
        display: block
        margin: 16px auto 0
    .textoModal
        text-align: center
        color: #000
        font-size: 1.2rem
        font-weight: 400
    .redbtn 
        background: white
        font-weight: 700
        padding: 8px 20px
        font-size: 18px
        color: var(--color-rojo-pale)
        position: relative
        text-align: center
        text-decoration: none
        margin-top: 20px
        float: right
.ReactModal__Body--open
    position: fixed
    overflow: hidden
    width: 100%
.ReactModal__Overlay--after-open
    background-color: rgba(153, 153, 153, 0.75) !important
    z-index: 2222 !important
.ReactModal__Content .ReactModal__Content--after-open
    overflow: initial !important
.ReactModal__Content
    top: 20% !important
    left: 50% !important
    right: auto !important
    bottom: auto
    margin-right: -50% !important
    transform: translate(-50%, -20%) !important
    +mediaMax580
        width: 450px !important  
    +mediaMax480
        width: 300px !important
    +mediaMax320
        width: 220px !important
button.fancybox-close 
    position: absolute
    top: 5px !important
    right: 5px !important
    width: 31px
    height: 31px
    cursor: pointer
    z-index: 8040
    background-color: #ffffff
    color: gray
    display: block
    border-radius: 50%
    border: 1px solid #ffffff
    font-size: 1.7rem
    z-index: 2210 !important
    font-weight: 700

.left
    text-align: left !important