$azul-facebook: #3b5998
.botonFacebook
    box-sizing: border-box
    padding: 0 20px 20px
    width: 100%
    max-width: 500px
    // min-width: 350px
    margin: -20px auto 0px
    font-weight: 600
    .is-dark-mode &
        background: var(--color-background-middle-dm)
    .facebook-button
        background: $azul-facebook
        border: 1px solid rgba(0, 0, 0, 0)
        font-weight: 600
        color: white
        align-items: center
        justify-content: center
        border-radius: 6px
        font-size: 13px
        cursor: pointer
        width: 100%
        height: 40px
        .fa-facebook-official
            padding-right: 10px
