@import 'variables64'

$experimental-support-for-svg: true

//-Varianles de dominio
$dominio: '/'
$dominioImg: $dominio+'img/'
$dominioVector: $dominio+'vector/'
$dominioFont: $dominio+'font/'

//sprites
$spr-layout: vertical
$spr-spacing: 5px
//@import "spr/*.png"
//@include all-spr-sprites
//@import "sprites"

//- Cache
$cacheBaja: "?v=1.0.0.0"
$cacheMedia: "?v=1.0.0.0"
$cacheAlta: "?v=1.0.0.0"

//-Marca
//$rojo1: #DA262C
//$rojo1: #EF3829
$rojo1: #C00
//$rojo1: #DC3224
//$rojo2: #B7202E
$rojo2: #B7202E
$rojo3: #d02e10
$azul: #1F97AE
//$azul: #035f71
// $azul1: #0695b2
// $azul2: #0496a9 
// $azul3: #2997ad
$azulClaro: #e8f4f6
$azulMedio: #A2D1D9
//$naranja: #F58220
$naranja: #F5842B
//$negro: #222
$negro: #000104
$gris: #ACAEAE
//gris2: #ebebeb
$verde: #44AF69

//- Variables
$blanco: #fff
$gris1: #f1f1f1
$gris2: #f5f5f5
$gris4: #707070
$gris5: #222
$gris6: #bbb
$primario: $rojo1
$rojo: $rojo2
$color-bg: #f2f2f2
$color-border: #DDD
$color-bgClaro: #eee
$color-gris: #888
$color-text: #666
$color-link2: #444
$color-text-dark: #333
//$color-black: #222
$color-black: #000104
//$color-link: #3481b7
$color-link: #035f71
$link-visited: #005b69
$link-visited: #147a8c
$transp: transparent


//$empresas: #afafaf
$empresas: #a6adb4
$empresas2: #838383
//$corporaciones: #000
$corporaciones: #000104
$corporaciones2: #404040

$color-btn-azul: $azul

//- Spaces
$spacer: 20px
$spacerX2: 40px
$min-spacer: calc($spacer/2)
$middle-spacer: calc($spacer/1.444444)
$new-spacer: 30px

// logo tamano
$LogoW: 100%
$logoH: 50px
$logoMT: 5px

$LogoWM: 122px
$logoHM: 30px

$headerMiddle: 45px
$posSearch: 45px


//-Redes sociales
$color-FB: #3b5998


//-Alerts
$info: #00529B
$success: #4F8A10
$warning: #f08a24
$error: #D8000C


//- icons
$bullet: "\002022"


//- Font
$fontPrin: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif
//$fontPrin: 'Muli', arial, helvetica, sans-serif
$fontSec: 'Roboto', arial, helvetica, sans-serif
$remVal: 16px
$fontSize: 16px
$fontHeight: $spacer


//- Breakpoints
$breakpoint-ss: 240px
$breakpoint-s: 320px
$breakpoint-sm: 480px
$breakpoint-m: 640px
$breakpoint-md: 780px
$breakpoint-d: 960px
$breakpoint-dl: 1100px
$breakpoint-lg: 1280px
$breakpoint-lt: 1400px
$breakpoint-xl: 1560px

//- Grid
$totalGrid: 15

$g15: 100%
$g14: 93.3333333333%
$g13: 86.6666666667%
$g12: 80%
$g11: 73.3333333333%
$g10: 66.6666666667%
$g9: 60%
$g8: 53.3333333333%
$g7: 46.6666666667%
$g6: 40%
$g5: 33.3333333333%
$g4: 26.6666666667%
$g3: 20%
$g2_5: 16.6666666667%
$g2_4: 0%
$g2: 13.3333333333%
$g1: 6.6666666667%

//- Iconos RS
$icons: ("fb":"\f082", "tw":"\f081", "yt":"\f166", "gp":"\f0d4", "in":"\f16d", "pi":"\f0d3", "linked":"\f08c")


//- Sombras
$sombraPrin: 0px 0px 2px 1px rgba(black,0.15)
$sombraSec: 0px 0px 7px 1px rgba(black,0.4)
$sombraTer: 0 9px 13px 0 rgba(black,0.24), 0 15px 40px 0 rgba(black,0.19)