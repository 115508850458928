footer
    background: black
    color: white
    box-sizing: border-box
    padding: 5px
    margin-top: 10px
    .is-dark-mode &
        background-color: #292f35
        color: white
    &.nhfooter
        background-color: white
        width: 100%
        padding: 0
        .is-dark-mode &
            background-color: #292f35
    p   
        margin: 0