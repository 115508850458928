@import '../../../../sass/_abstracts'

#contImages
    .countImageDesktop
        position: absolute
        left: 100px
        z-index: 2
        padding-left: 25px
        box-sizing: border-box
        user-select: none
        background-color: rgba(white,0.6)
        &:before
            content: ""
            background-image: url(/img/ico-pick.svg)
            background-position: center center
            background-repeat: no-repeat
            background-size: contain
            opacity: 0.6
            width: 17px
            height: 17px
            position: absolute
            left: 0
.contImagesProductDesktop
    padding-top: 25px
    width: 100%
    max-width: 50%
    min-width: 270px
    min-height: 490px
    max-height: 490px
    position: relative
    .is-dark-mode &
        background: var(--color-background-dm)
    @media only screen and (max-width: 900px)
        max-width: 100%
        min-height: 490px
    +mediaMax780
        margin-left: auto
        margin-right: auto
        padding: 20px
    .btnWishlist
        // top: 5px !important
        top: 7px !important
        // border: 1px solid var(--color-gris-borde)
        border-radius: 50%
        padding: 4px
        box-shadow: 1px 1px 5px rgba(50, 50, 50, 0.2)
        +mediaMax780
            margin-top: 20px
            top: 0px !important
    .shareModulesDetail
        // max-width: 100%
        // width: 100%
        position: absolute
        height: 35px
        text-align: right
        right: 0
        z-index: 1
        +mediaMax780
            top: 1px
        .shareBtn
            float: right
            background-color: #ffffff99
            border-radius: 50%
            padding: 4px
        +mediaMax780
            float: right
            // position: relative
    .contSliderDesktop
        // display: flex
        max-height: 460px
        height: 100%
        // align-items: center
        @media only screen and (max-width: 900px)
            width: 100%
        .grandeS
            width: 100%//calc(100% - 90px)
            order: 1//2
            max-height: 480px
            overflow: hidden
        .pequeS
            width: 100%
            // max-width: 450px//92px
            box-sizing: border-box
            // padding: 0 10px
            order: 2//1
            height: 90px//420px
            img
                max-height: 50px
                max-width: 50px
            .slick-slide
                width: 50px !important
                height: 50px
                margin-bottom: 5px
                padding: 3px
            .slick-prev,
            .slick-next
                // position: relative
                // transform: rotate(90deg)
                border-radius: 0px
                margin-left: 8px
                background: rgba(0, 0, 0, 0.0) !important
                height: 30px !important
                opacity: 1 !important
                &::before
                    content: " "//"\F106" !important
                    font-size: 30px
                    background-image: url(/img/group-9.svg) !important//none !important
                    background-size: contain
                    width: 42px !important
            .slick-prev
                height: 30px !important
                &::before
                    transform: rotate(-180deg)
            .slick-next
                height: 30px !important
                &::before
                    // transform: rotate(90deg)
            .slick-list
                // height: 330px !important
                height: 55px
                // width: 450px
                padding-top: 8px
                .slick-track
                    width: 100% !important
                    transform: translate3d(40px, 0px, 0px) !important

        img
            width: 100%
            object-fit: contain
            max-height: 450px
        .modal11
            overflow-y: hidden
            .bodyZoomDesktop
                position: absolute
                overflow: hidden
                transform: translate(-50%,-50%)
                top: 50%
                left: 50%
                width: 100vw
                max-width: 1400px
                .contZoomRelativeDesktop
                    position: relative
                    box-sizing: border-box
                    padding: 40px
                    width: 100%
                    display: flex
                    flex-direction: row
                    .contentZoomDesktop
                        width: 100%
                        display: block
                        .modal-headerDesktop
                            position: relative
                            float: right
                            width: 50px
                            height: 25px
                            cursor: pointer
                            color: white
                            background-color: rgba(0,0,0,.25)
                            border: none
                            font-weight: 600
                            font-size: 20px
                            padding: 5px
                            background-position: 50%
                            &::after
                                content: "x"
                        .contenidoModalDesktop
                            text-align: center
                            width: 100%
                            padding-top: 20px
                            // .zoomModal
                            //     overflow: scroll
                            //     transform: scale(1.8)

                            .slick-slide
                                img
                                    width: 500px
                                &.slick-active.slick-current
                                    // img.zoom
                                    //     transform: scale(1.8)
                                    //     overflow-y: scroll
                                    //     background-position: center
                                    //     background-size: cover
                                    //     transition: all .5s
                            .slick-slider
                                margin-bottom: 6px
                            .slick-next, .slick-prev
                                border-radius: 0px
                            .cajaCantidadDesktop
                                width: 10%
                                text-align: center
                                background-color: rgba(0,0,0,.25)
                                margin-left: auto
                                margin-right: auto
                                border-radius: 7px
                                padding: 5px 0
                                color: #888

    .contDataSliderDesktop
        position: absolute
        width: 100%
        height: 25px
        bottom: 80px//40px
        +mediaMax780
            margin-left: auto
            margin-right: auto
            padding-bottom: 10px
            width: calc(100% - 32px)
        .contenedorCantidadWish
            display: flex
            width: 100%
            align-items: center
            .countImageDesktop
                position: absolute
                left: 10px
                z-index: 2
                padding-left: 25px
                box-sizing: border-box
                user-select: none
                background-color: rgba(white,0.6)
                top: 5px
                &:before
                    content: ""
                    background-image: url(/img/ico-pick.svg)
                    background-position: center center
                    background-repeat: no-repeat
                    background-size: contain
                    opacity: 0.6
                    width: 17px
                    height: 17px
                    position: absolute
                    left: 0
            .btnWishlist
                top: -7px !important
                // border: 1px solid var(--color-gris-borde)
                border-radius: 50%
                padding: 4px
                box-shadow: 1px 1px 5px rgba(50, 50, 50, 0.2)
            .shareModulesDetail
                // max-width: 100%
                // width: 100%
                position: absolute
                height: 35px
                text-align: right
                right: 0
                z-index: 1
                top: 1px
                .shareBtn
                    float: right
                    border: 1px solid var(--color-gris-borde)
                    border-radius: 50%
                    padding: 4px
                    box-shadow: 1px 1px 5px rgba(50, 50, 50, 0.4)
                +mediaMax780
                    float: right
                    position: relative

.ReactModal__Body--open
    &.open
        .ReactModal__Overlay,
        .ReactModal__Overlay--after-open
            .ReactModal__Content
                top: 0 !important
                left: 0 !important
                right: 0 !important
                bottom: auto
                margin-right: 0 !important
                transform: none !important
                +mediaMax780
                    width: auto !important
.ril-zoom-in,
.ril-zoom-out
    // display: none
.ril-image-current
    cursor: -moz-zoom-in
    cursor: zoom-in
.ril__toolbar
    background-color: rgba(0, 0, 0, 0) !important
.ril-close
    width: 50px !important
    height: 50px !important
    //background-color: rgba(0, 0, 0, 0.5) !important
    background-color: rgba(100, 100, 100, 0.7) !important
.ril__caption
    bottom: 0
    max-height: 150px
    overflow: hidden !important
    width: 75px !important
    margin-left: auto
    margin-right: auto
.ril__image,
.ril-image-current
    max-width: 550px !important
    min-width: 400px !important
    width: 100%
    margin-left: auto !important
    margin-right: auto !important
    +mediaMax780
        width: auto !important
        max-width: none !important
        min-width: none !important
.ril__inner
    width: 100% !important
    margin-left: auto !important
    margin-right: auto !important

.imagenActual,
.imagenInferiorActual
    cursor: pointer

.ril__navButtonPrev,
.ril__navButtonNext
    background-color: rgba(100, 100, 100, 0.7)!important

// .slick-vertical
.pequeS
    .slick-slider
        .slick-list
            .slick-slide
                border: 1px solid #e6e6e6
                margin-left: 5px
                &.slick-active
                    border-color: var(--color-rojo-pale)
                    border: 1px solid red
