.contentMisDatos
    padding: 20px
    font-size: 20px
    text-align: center
    p
        font-weight: bold
        font-size: 22px
        display: inline-block

.contentMisDatos2
    padding: 20px
    font-size: 20px
    text-align: center
    p
        font-weight: normal
        font-size: 22px
        display: block
        span
            font-weight: bold
            font-size: 22px
            display: inline-block
            word-break: break-all

.prelogin
    padding-bottom: 60px
    min-height: 100vh
    height: auto