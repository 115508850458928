@import '../../../../../sass/_abstracts'

.marginAD
    margin-left: auto !important
    margin-right: auto !important
    border-left: none !important
.miCuentaContainer
    margin-bottom: 50px
    .containerAD
        display: inline
        .boxBtnRegresarDktop
            margin-bottom: 20px
            margin-top: 30px
            display: flex
            flex-direction: row
            align-items: center
            padding: 20px 50px
            box-sizing: border-box
            position: sticky
            background: white
            z-index: 1
            .is-dark-mode &
                background-color: var(--color-background-dm)
                color: white
            .btnBackAD
                background: none
                border: none
                outline: none
                font-size: 23px
                cursor: pointer
                .is-dark-mode &
                    color: white
            .icoBack
                position: relative
                display: inline
                right: inherit
                margin-bottom: 0
                margin-right: 5px
                background-color: white
                color: black
                transform: rotate(180deg)
                cursor: pointer
                width: 25px
                padding: 5px
                height: 25px
                text-align: center
                line-height: 0.9
                .is-dark-mode &
                    color: white
                    background-color: transparent
                &::before
                    content: '\2794'
                    font-size: 18px
        .boxGeneralAD
            width: 91%
            display: flex
            margin-left: auto
            margin-right: auto
            +mediaMax780
                display: block
            .boxLeftAD
                width: 30%
                +mediaMax780
                    width: 90%
                    margin-left: auto
                    margin-right: auto
                .contSectionRed
                    background: var(--color-rojo-pale)
                    color: white
                    -webkit-box-sizing: border-box
                    box-sizing: border-box
                    display: -webkit-box
                    display: -ms-flexbox
                    webkit-display: -webkit-flex
                    display: flex
                    flex-direction: row
                    -webkit-box-direction: row
                    -ms-box-direction: row
                    -ms-flex-direction: row
                    -webkit-box-align: center
                    align-items: center
                    -webkit-align-items: center
                    -ms-flex-align: center
                    padding: 30px
                    border-radius: 15px
                    .imaDesktop
                        display: -webkit-box
                        display: -ms-flexbox
                        webkit-display: -webkit-flex
                        display: flex
                        flex-direction: row
                        -webkit-box-direction: row
                        -ms-box-direction: row
                        -ms-flex-direction: row
                        flex-wrap: wrap
                        -webkit-box-wrap: wrap
                        -webkit-flex-wrap: wrap
                        -ms-flex-wrap: wrap
                        width: 70px
                        height: 70px
                        border-radius: 20%
                        max-width: 100%
                        min-width: 70px
                        min-height: 70px
                        background: white
                        overflow: hidden
                    .imaDesktop.more
                        img
                            width: calc(50% - 1px)
                            max-height: 35px
                            height: auto
                            border-right: 1px solid var(--color-gris-borde)
                            border-bottom: 1px solid var(--color-gris-borde)
                    .imaDesktop
                        img
                            width: 100%
                            height: auto

                    .textDektop
                        font-weight: 600
                        font-size: 22px
                        width: calc(100% - 70px)
            .boxRightAD
                width: 70%
                margin-left: 10px
                border-left: 1px solid var(--color-gris-borde)
                min-height: 400px
                height: 100%
                .is-dark-mode &
                    border-left: 1px solid #484848
                +mediaMax780
                    width: 90%
                    margin-left: auto
                    margin-right: auto
                    border-left: none !important
                    
                .copyEspera
                    background: none
                    margin-top: 0 !important
                .boxSolicitarDevolucion
                    +mediaMax580
                        width: 100%
                .boxCancelacionExitosa
                    .boxContenido
                        width: 90%
                        margin-left: auto
                        margin-right: auto
                        +mediaMax780
                            margin-top: 50px !important
                            width: 100%
                    .boxBtnRegresar
                        +mediaMax780
                            width: 50%
                .copyFormAD
                    +mediaMax780
                        margin-top: 40px
                    p
                        text-align: left
                        padding-left: 50px
                        +mediaMax580
                            padding-left: 0
                .formInformacionBancaria
                    display: grid
                    margin-left: 50px
                    width: 60%
                    +mediaMax580
                        width: 100%
                        margin-left: 0
                    .fileset
                        margin-bottom: 30px
                    .fileset1
                        margin-bottom: 30px
                        margin-top: 15px
                    .desktInput
                        text-align: left
                    .selectAD
                        border: 1px solid var(--color-gris-borde)
                        border-radius: 5px
                        background-position: 98% center
                    .bottomDesktop
                        margin-top: 10px
                .normasL1
                    text-align: left
                .boxGeneralAdjuntar
                    width: 90%
                    margin-left: auto
                    margin-right: auto
                    +mediaMax580
                        width: 100%
                    .copy1,
                    .copy2
                        text-align: left
                        margin-left: 20px
                    .boxBtnContinuarFotos
                        width: 50%
                    .listFotografias
                        text-align: left
                .contenedorListadoAD
                    margin-top: 10px
                    width: 60%
                    margin-left: 20px
                    +mediaMax780
                        margin-top: 30px
                    +mediaMax580
                        width: 100%
                        margin-left: 0
                    a
                        text-decoration: none
                    li
                        list-style: none
                        padding: 10px 20px
                        box-sizing: border-box
                        text-align: left
                        position: relative
                        cursor: pointer
                        overflow: hidden
                        margin-bottom: 10px
                        margin-top: -1px
                        font-size: 16px
                        color: #4a4a4a
                        background: white
                        text-decoration: none
                        .is-dark-mode &
                            background-color: #292929
                            color: white
                        &::after
                            content: ''
                            position: absolute
                            right: 20px
                            top: 50%
                            margin-top: -6px
                            width: 10px
                            height: 10px
                            display: block
                            border-top: 2px solid
                            border-right: 2px solid
                            transform: rotate(45deg)
                            color: #4a4a4a
                            .is-dark-mode &
                                color: white
                .boxMotivoCancelacionAD
                    display: block
                    width: 40%
                    margin-left: auto
                    margin-right: auto
                    margin-top: 20px
                    +mediaMax780
                        width: 90%
                        margin-top: 60px
                    .inputMotivo
                        width: 100%
                        border-top: none
                        border-right: none
                        border-left: none
                        margin-bottom: 5px
                        outline: none
                        &::placeholder
                            color: #666666
                            font-size: 13px
                            font-family: Arial, Helvetica, sans-serif

                    .inputMotivo2
                        width: 100%
                        border-top: none
                        border-right: none
                        border-left: none
                        margin-bottom: 5px
                        outline: none
                        height: 20px
                        -webkit-box-sizing: border-box
                        -moz-box-sizing: border-box
                        box-sizing: border-box
                    .contadorCaracteres
                        text-align: right
                        font-size: 12px
                        color: gray
                    .boxBtn
                        width: 70%
                        margin-left: auto
                        margin-right: auto
                        margin-top: 70px
                        +mediaMax780
                            width: 100%
                        .btnContinuar
                            cursor: pointer
                            width: 100%
                            box-sizing: border-box
                            background: var(--color-rojo-pale)
                            height: 40px
                            display: block
                            float: left
                            border: none
                            color: white
                            border-radius: 5px
                            margin-left: 10px
                            +mediaMax780
                                margin-left: 0

.boxUlRazonesAD
    margin-top: 10px
    width: 60%
    margin-left: 20px
    +mediaMax780
        margin-left: 0
        width: 100%
    ul
        li
            display: flex
            align-content: center
            border-bottom: none
            align-items: center
            padding: 20px
            min-height: 20px
            box-sizing: border-box
            cursor: pointer
            border-bottom: 1px solid var(--color-gris-borde)
            .is-dark-mode &
                border-bottom: 1px solid #484848
            .nombreRazon
                text-align: left
                width: 98%
            .iconoRazon
                &::after
                    content: ''
                    right: 20px
                    width: 10px
                    height: 10px
                    display: block
                    border-top: 2px solid
                    border-right: 2px solid
                    transform: rotate(45deg)

.sinMarginTop
    margin-top: 0 !important
    +mediaMax780
        margin-top: 40px
.mensajeError
    color: var(--color-rojo-pale)
