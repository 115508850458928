@import '../../../sass/_abstracts'

$spacer16: 16px
$spacer: 20px
$min-spacer: calc($spacer/2)
$color-border: #DDD
$color-text: #555
$color-text-dark: #333
$color-gris: #888
$azulLink: #4990e2
$color-bgClaro: #eee
$rojo2: #B7202E
$rojoBtn: #C00

.login
    display: block
    position: relative
    list-style: none
    +mediaMax320
        width: 65px
    +mediaMax480
        width: 50px
    +mediaMax780
        width: 60px
        display: flex !important
    &:hover
        .box__login
            opacity: 1
            filter: alpha(opacity=100)
            visibility: visible
            -webkit-transition: 0.5s
            transition: 0.5s
            display: table
            width: 250px
    .smallText
        display: block
        +mediaMax780
            display: none !important
    .textReference
        +mediaMax780
            padding-top: 9px
    .box__login
        box-sizing: border-box
        position: absolute
        padding: $spacer16
        top: 45px
        left: -100px
        width: 250px
        background: white
        border: 1px solid $color-border
        display: none
        border-radius: 5px
        box-shadow: 1px 2px 5px rgba(50,50,50,0.4)
        z-index: 100
        dt
            padding: 5px
            text-align: left
        .is-dark-mode &
            filter: invert(1)
            background-color: var(--color-background-middle-dm)
            border: 1px solid var(--color-background-middle-dm)
        +mediaMax780
            bottom: 49px
            right: -50px
            left: initial
            top: initial
            padding: 16px
        &:before
            content: " "
            background: url(/img/tri.webp) no-repeat center !important
            position: absolute
            width: 100% !important
            height: 20px !important
            top: -20px
            right: -5px
            z-index: 160
            .is-dark-mode &
                opacity: 0.7
                filter: invert(1)
            +mediaMax780
                transform: rotate(180deg)
                top: inherit
                left: 26px
                bottom: -20px
        a
            height: auto
            width: auto
            &:before
                display: none
        dl
            font-size: 0.8rem
            color: $color-text
            &:not(.logIn)
                a,
                .cerrar
                    color: #666
                    text-align: left
                    padding: 4px
                    width: 100%
                    text-decoration: none
                    cursor: pointer
                    &:hover
                        color: $azulLink
                        text-decoration: underline
        p
            display: block
            padding-top: 2px !important
            width: 100%
            font-size: .8rem
            text-align: center
            .is-dark-mode &
                color: white
        .logIn
            border-bottom: none
            max-width: 160px
            margin: auto
            a
                text-decoration: none
                &:hover
                    text-decoration: underline
                &.redBtn
                    text-align: center
                    background: var(--color-rojo-pale)
                    border: 1px solid #ff0000
                    color: white
                    &:hover
                        text-decoration: none
            >dd
                font-size: 0.7rem
            p
                padding: 8px 0 12px
        .logOut
            display: block
            margin-top: 5px
            margin-bottom: 0
            padding-bottom: 0
            .cerrar
                color: $azulLink !important
                text-align: center !important
                padding: 4px 0
                margin: 4px auto 0
                &:hover
                    text-decoration: underline
            p
                display: block
                width: 100%
                font-size: 0.8rem
                text-align: center
                right: 0
                bottom: 0

            dt
                margin-bottom: calc($min-spacer/4)

    &.log
        .box__login
            display: table

    #userInformation
        display: block
        margin: auto
        padding: 0
        text-align: left
        +mediaMax780
            text-align: center
        +mediaMax480
            width: 70px
        &:before
            font-size: 25px
            font-weight: 500
            +mediaMax780
                font-size: 24px
    p
        padding: 8px 0 12px

    .redBtn,
    .standardBtn
        flex-direction: row
        color: inherit
        text-decoration: none
        -webkit-box-sizing: border-box
        box-sizing: border-box
        margin-bottom: 10px
        font-size: 13px
        text-align: center
        border-radius: 15px
        display: table
        cursor: pointer
        padding: 8px 0
        font-weight: 300
        text-align: center !important
        text-decoration: none !important
        -webkit-box-orient: horizontal
        -webkit-box-direction: normal
        -webkit-box-align: center
        align-items: center
        -webkit-box-pack: center
        justify-content: center
        width: 160px !important
    .redBtn
        background: var(--color-rojo-pale)
        border: 1px solid var(--color-rojo-pale)
        color: white
        &:hover
            background: var(--color-rojo-pale)
            border: 1px solid var(--color-rojo-pale)
            color: white
    .standardBtn
        background-color: $color-bgClaro
        border: 1px solid $color-bgClaro
        color: $color-text-dark
        &:hover
            background-color: $color-gris
            border: 1px solid $color-gris
            color: white
