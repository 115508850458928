@import '../../../sass/_abstracts'

.errorMessageServices
	text-align: left
	margin: 40px 0
	max-width: 960px
	padding: 0 20px
	font-size: 1.75rem
	line-height: 35px
	p
		margin: 20px 0

	@media only screen and (max-width: 780px)
		margin: 20px 0 60px
		font-size: 1.3rem
		.is-dark-mode &
			color: var(--color-gris-borde)
	.bold
		font-weight: bold
	.errorImage
		width: 100%
		height: auto
		max-width: 400px
		margin: 0px auto
		.is-dark-mode &
			opacity: 0.8
