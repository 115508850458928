//- Modulo de Abstracts con variables, funciones y mixines
@import './abstracts'

//- Styles del portal
\:root
	--color-rojo: #FF0203
	--color-rojo-dm: #f56868
	--color-rojo-pale: #FF0203
	--color-rojo-pale-dm: #f56868
	--color-sanborns: #FF0203
	--color-azul: #2591b4
	--color-azul-dm: #78b1f3
	--color-azul-telmex: #1078af
	--color-verde: #009966
	--color-verde-dm: #3dd026
	--color-naranja: #F7641F
	--color-naranja-dm: #3dd026
	--color-gold: #F5A623
	--color-gris-fondo: #FFF
	//--color-gris-fondo: #F3F3F3
	--color-gris-fondo-dm: #1A1A1A
	--color-gris-claro: #F3F3F3
	--color-gris-borde: #DDD
	--color-text: #000
	--color-text-oscuro: #343434
	--color-text-calro: #9E9E9E
	--color-text-calro-dm: #AAA
	--color-text-placeholder: #9E9E9E
	--color-text-placeholder-dm: #CCC
	--color-background-calro: steelblue
	--color-gold: darkgoldenrod
	--color-background-dm: #272727
	--color-background-middle-dm: #292f35
	--filter-invert: invert(0)
	--image-opacity-dm: 0.8

html,
body
	font:
		family: Muli
		size: $fontSize
	-webkit-font-smoothing: antialiased
	-moz-osx-font-smoothing: grayscale
	//background: #EEE
	background: var(--color-gris-fondo)
	min-height: 100vh
	&.is-dark-mode
		background: var(--color-gris-fondo-dm)

	@media (prefers-reduced-motion: reduce)
		*
			&:before,
			&:after
				animation-delay: -1ms !important
				animation-duration: -1ms !important
				animation-iteration-count: -1ms !important
				transition-duration: -1ms !important
				transition-delay: -1ms !important

// *
// 	-ms-overflow-style: none
//     scrollbar-width: none
// 	&:-webkit-scrollbar
// 		width: 4px
// 		height: 4px
// 	&:-webkit-scrollbar-thumb
// 		background: none
// 		&:hover
// 			background: #AAA
// 	&:-webkit-scrollbar-track
// 		background: none
// 		&:hover
// 			background: rogba(#DDD,0.7)

.desktopView,
.fullHeader
	--color-rojo-pale: #ff0000
	--color-verde: #117c00
	--color-azul: #2591b4
	--color-text-calro: #666

code
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace

#root
	text-align: center

main
	min-height: calc(70vh - 116px)
	.desktopView &
		min-height: calc(70vh - 116px)

img
	image-rendering: -webkit-optimize-contrast

.LazyLoad
	width: auto !important
	height: auto !important

// section,
// article
// 	content-visibility: auto

.wrapper
	min-height: 550px
	background: #f5f2f1
	padding-top: 0!important

.container
	box-sizing: border-box
	position: relative
	width: 100%
	//max-width: 1500px
	max-width: 100%
	height: auto
	margin: auto
	padding: 0 0px
	.desktopView &
		padding: 0 20px
		max-width: 96%
	@media only screen and (max-width: 760px)
		padding: 0
		max-width: 100%

.miCuentaMenu
	display: flex
	flex-firection: row
	flex-wrap: wrap
	padding: 50px 0 20px
	box-sizing: border-box
	.card
		&.btn
			box-sizing: border-box
			width: 100%
			max-width: calc(33.3% - 20px)
			margin: 10px
			padding: 45px
			font-size: 16px
			font-weight: 700
			background-color: white//#eee
			color: inherit
			box-shadow: -2px 2px 13px rgba(0, 0, 0, 0.15)
			&:hover
				background-color: #eee
			@media only screen and (max-width: 1100px)
				max-width: calc(50% - 20px)
			@media only screen and (max-width: 780px)
				max-width: calc(100% - 20px)
			.is-dark-mode &
				background: var(--color-text-calro)
		&.myOrders,
		&.myAddress,
		&.myWishlist,
		&.myProfile,
		&.myPyment,
		&.myCuppon,
		&.myTelmex,
		&.logout
			&:before
				content: ''
				margin-right: 10px
				background-size: contain
				background-repeat: no-repeat
				background-position: center
				width: 50px
				height: 40px
				margin-left: -30px
				display: inline-block
				.is-dark-mode &
					filter: invert(1)

		&.myTelmex
			&:before
				background-image: url(/img/icoTelmex.png)
		&.myOrders
			&:before
				background-image: url(/img/icono_mis_pedidos.svg)
		&.myAddress
			&:before
				background-image: url(/img/icono_mis_direcciones.svg)
				height: 50px
		&.myWishlist
			&:before
				background-image: url(/img/icono_mi_lista_de_deseos.svg)
		&.myProfile
			&:before
				background-image: url(/img/icono_mi_perfil.svg)
				height: 50px
		&.myPyment
			&:before
				background-image: url(/img/icono_mis_metodos_de_pago.svg)
		&.myCuppon
			&:before
				background-image: url(/img/icono_mis_cuponess.svg)
				height: 50px
		&.logout
			&:before
				background-image: url(/img/icono_mi_logout.svg)

.cf
	@extend %cf

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6
	&.light,
	&.normal
		font-weight: 400
	&.medium
		font-weight: 500
	&.bold
		font-weight: 700

.btn
	-webkit-box-sizing: border-box
	box-sizing: border-box
	display: flex
	flex-direction: row
	align-items: center
	justify-content: center
	border-radius: 6px
	margin-bottom: 10px
	font-size: 14px
	font-weight: 700
	text-decoration: none
	padding: 8px 16px
	cursor: pointer
	width: 100%
	height: 42px
	user-select: none
	&:focus
		outline: none
	&.rojoSanborns
		border-radius: 6px
		background: var(--color-rojo-pale)
		color: #fff
		border: 1px solid rgba(0,0,0,0)
		font-weight: 500
		&:active
			background-color: darken(#C00,10)
	&.rojo
		background: var(--color-rojo-pale)
		color: #fff
		border: 1px solid rgba(0,0,0,0)
		font-weight: 500
		&:active
			background-color: darken(#C00,10)
		&.line
			color: var(--color-sanborns)
			background: white
			border: 1px solid var(--color-sanborns)
	&.blancoGris
		background-color: #FFF
		border: 1px solid #6a6a6a75
		font-weight: 600
		border-radius: 6px
		color: #333
		&:hover
			background-color: darken(#ffffff,8)
	&.blanco
		background-color: #FFF
		color: var(--color-text-oscuro)
		border: 1px solid #c2c2c275
		font-weight: 500
		&:active
			background-color: darken(#FFF,10)
		.is-dark-mode &
			background-color: var(--color-text-placeholder-dm)
			color: black
			border: 1px solid var(--color-gris-borde)
			font-weight: 500
	&.gris
		background-color: var(--color-gris-claro)
		color: var(--color-text-oscuro)
		border: 1px solid rgba(#ddd,1)
		font-weight: 500
		&:active
			background-color: darken(#DDD,10)
	&.facebook
		background-color: #3B5998
		color: #FFF
		border: 1px solid rgba(#3B5998,0)
		font-weight: 500
		&:active
			background-color: darken(#3B5998,10)
	&.next
		&:after
			content: ">"
			margin-left: 5px
	&.prev
		&:before
			content: "<"
			margin-right: 5px
	&.plus
		&:after
			content: "+"
			margin-left: 9px
			font-weight: 700
			font-size: 14px
			border-radius: 50%
			border: 2px solid red
			width: 17px
			height: 16px
			text-align: center
	&:disabled
		cursor: default
		opacity: 0.6

ul
	&.dottedList
		li
			position: relative
			margin-bottom: $min-spacer
			&:before
				color: $azul !important
				content: $bullet
				margin-right: $min-spacer
			&:last-child
				margin-bottom: 0
	&.dottedline
		li
			font-weight: 400
			margin-bottom: $min-spacer
			&:before
				content: '-'
				display: inline-block

.ventana_emergente,
.ventana_sussess
	+bx
	display: none
	padding: 8px 16px 8px 41.6px
	background: #f2f2f2
	margin: 0 0 12px
	width: 100%
	position: relative
.ventana_emergente
	border: 1px solid var(--color-rojo-pale)
.ventana_sussess
	border: 1px solid var(--color-verde)
	background: rgba(17,124,0,0.3)

.fileset
	.error
		box-sizing: border-box
		color: var(--color-rojo)
		text-align: left
		padding: 10px 0
		font-size: 13px

.contProductCategory
	border-bottom: 1px solid var(--color-gris-borde)

.link
	color: var(--color-azul)
	text-decoration: underline
	cursor: pointer
	.is-dark-mode &
		color: #78b1f3
	&:active
		color: darken(#4990e2,15)
.w50
	width: 50%
	margin: auto
.decoracionNone
	text-decoration: none

.sliderPrincipal
	max-height: 400px
	.contSlideP
		overflow: auto
		>
			a
				display: table-cell
				padding: 0 20px
.slick-slide
	height: auto
	box-sizing: border-box
	img
		height: 100%
		margin: auto
		width: 95%
		max-height: 370px
		box-sizing: border-box

.cardSliderHome
	position: relative
	p
		position: absolute
		display: none
		visibility: hidden

.slick-prev,
.slick-next
	//background: #fc0303b3 !important
	z-index: 1 !important
	//opacity: 0 !important
	width: 50px !important
	height: 50px !important
	//opacity: 0.5 !important
	&:hover
		opacity: 1 !important
	&:before
		content: "" !important
		color: transparent !important
		background-position: center !important
		background-repeat: no-repeat !important
		background-size: 70% !important
		width: 50px
		height: 50px
		opacity: 1 !important
		// padding: 2px
		display: block

.slick-prev
	left: 0 !important
	//border-radius: 50%
	&:before
		transform: rotate(-180deg)
		background-image: url(/img/group-14-copy-2.png)

.slick-next
	right: 0 !important
	border-radius: 50%
	&:before
		background-image: url(/img/group-14-copy-2.png)		
// .slick-slider
// 	&.slick-initialized
// 		&:hover
// 			.slick-prev,
// 			.slick-next 
// 				opacity: 1 !important

//- Base de skeleton
.skeleton
	position: relative
	widht: 100%
	display: block!important
	.bones
		//box-shadow: 0 4px 10px 0 rgba(33, 33, 33, 0.15)
		border-radius: 4px
		position: relative
		overflow: hidden
		//background-color: skyblue
		background-color: #b1bec6
		opacity: 0.3
		// background-image: url(https://resources.claroshop.com/medios-plazavip/swift/v1/app/gifs/loader-easing.gif)
		// background-image: url(https://resources.claroshop.com/medios-plazavip/swift/v1/app/accesosRapidos/iconos_C_Videojuegos.png)
		// background-image: url(https://resources.claroshop.com/medios-plazavip/swift/v1/app/gifs/loading_classic.gif)
		background-repeat: no-repeat
		//background-size: 100% 100%
		background-size: cover
		// &:before
		// 	content: " "
		// 	display: block
		// 	position: absolute
		// 	width: 100%
		// 	height: 100%

//- skeleton card de producto
.cardProduct
	&.skeleton
		.cardGrid &
			max-width: calc(50% - 10px)
			.desktopView &
				max-width: calc(25% - 20px)
		.bones
			&.btnWishlist
				width: 25px
				border-radius: 50%
				opacity: 0.5
				&:after
					content: none
			&.img
				display: block
				position: relative
				margin: auto
				max-height: 230px
				height: 100vh
				min-height: 140px
				max-width: 80%
				width: 100%
				.cardLine &
					max-width: 30%
					max-height: 200px
				@media only screen and (max-width: 600px)
					max-height: 140px
					max-width: 50%
			&.contDataCard
				background-color: transparent
				box-shadow: none
				opacity: 1
			&.h4
				width: 100%
				display: block
				height: 14px
			&.h5
				width: 80%
				height: 4px
				margin-bottom: 8px
			&.envioGratis
				width: 53%
				display: block
				height: 20px
			.cardSkel
				display: flex
				flex-direction: row

		.cardSkel
			display: flex
			width: 100%
			color: inherit
			text-decoration: none
			flex-direction: column
			.cardLine &
				flex-direction: row

//-skeleton slider Principal
.sliderPrincipal
	.container
		&.skeleton
			overflow: scroll
			box-sizing: border-box
			display: -webkit-box!important
			.img
				&.bones
					width: 100%
					max-width: calc(100% - 40px)
					aspect-ratio: 15/7
					margin: 0 20px
					&:before
						content: ""
						width: 1px
						margin-left: -2px
						float: left
						height: 0
						padding-top: calc((450px / 800px) * 100%)
					&:after
						content: ""
						display: table
						clear: both

//-skeleton Promotion
.promotionA1
	&.skeleton
		overflow: scroll
		box-sizing: border-box
		display: flex
		.bones
			width: 100%
			max-width: calc(100% - 20px)
			aspect-ratio: 4/3
			margin: 10px
			display: inline-block
			&:nth-child(2)
				max-width: calc(50% - 20px)
				aspect-ratio: 2/3
			&:nth-child(3)
				max-width: calc(50% - 20px)
				aspect-ratio: 2/3
			.desktopView &
				margin: 0 10px
				max-width: calc(33.3% - 20px)
				aspect-ratio: 4/3

//-fulfilment
.fullFilment
	box-sizing: border-box
	background-image: url(/img/fullf.png)//url(https://resources.claroshop.com/medios-plazavip/swift/v1/claroshop/assets/img/express.png)
	background-size: contain
	background-repeat: no-repeat
	width: 100%
	height: auto
	max-width: 80px
	display: block
	min-height: 24px
	color: transparent
	background-position: center left
	margin: 3px 0 3px
	font-size: 0
	.is-dark-mode &
		//color: #3dd026

.errorCrashWhitePage
	display: none
	padding: 20px
	box-sizing: border-box
	border: 1px solid var(--color-gris-borde)
	margin: 10px auto
	border-radius: 5px
	h2
		padding: 10px 0
		font-size: 20px
		font-weight: 600
	p
		padding: 10px 0
		font-size: 16px
		font-weight: 400

.codeErrorResume
	display: none!important

.errorCrashWhitePageMinimal
	display: none!important

.contentImageFF
	width: 80%
	margin-left: auto
	margin-right: auto
	img
		max-width: 90%
		height: auto
		object-fit: scale-down
		justify-content: center
		display: flex
		margin-top: 20px
		margin-left: auto
		margin-right: auto
	ul
		//list-style: disc
		margin-top: 30px
		li
			display: flex
			flex-direction: row
			padding: 10px 2px
		.icoFF1,
		.icoFF2,
		.icoFF3
			&:before
				content: ""
				width: 30px
				box-sizing: border-box
				height: 20px
				background-position: right
				background-size: contain
				background-repeat: no-repeat
				display: block
				margin-right: 10px
				min-width: 30px
		.icoFF1
			&:before
				background-image: url(/img/clockFast.svg)
		.icoFF2
			&:before
				background-image: url(/img/ubicationFast.svg)
		.icoFF3
			&:before
				background-image: url(/img/devolucionFast.svg)

	.boxEnteradoFull
		justify-content: center
		display: flex
		margin-top: 20px
		.btnEnteradoFull
			flex-direction: row
			text-decoration: none
			-webkit-box-sizing: border-box
			box-sizing: border-box
			margin-bottom: 10px
			font-size: 13px
			text-align: center
			border-radius: 6px
			display: table
			cursor: pointer
			padding: 8px 0
			font-weight: 300
			text-align: center !important
			text-decoration: none !important
			-webkit-box-orient: horizontal
			-webkit-box-direction: normal
			-webkit-box-align: center
			align-items: center
			-webkit-box-pack: center
			justify-content: center
			width: 100%//160px !important
			background: var(--color-rojo-pale)
			color: white
			border: none
			font-weight: 600
.modalPreLogin
	img
		max-height: 190px
		margin: 50px auto
		display: flex
	p
		padding: 0 30px 20px
		text-align: center
		line-height: 1.5
		max-width: 330px
		margin: auto
		box-sizing: border-box
		strong
			font-weight: 600
	.btn
		&.rojo
			margin-bottom: 30px

// .videoYtProduct
// 	object-fit: scale-down
// 	max-height: 450px
// 	height: 100vh
// 	position: relative
// 	iframe
// 		&.ytVideo
// 			width: 100%
// 			aspect-ratio: 16/9
// 			height: 320px
// 			position: absolute
// 			top: 50%
// 			margin-top: -160px
// 			left: 0

.videoYtProduct
	object-fit: scale-down
	height: auto
	position: relative
	iframe
		&.ytVideo
			margin-top: 40px
			width: 100%
			aspect-ratio: 16/9
			height: auto
			max-height: 80vh

.modal
	&.showVideoP
		.contentModal
			min-width: 70%
.videoProduct
	display: block
	background-image: url(/img/ic_video.png)
	background-size: cover
	background-repeat: no-repeat
	cursor: pointer
	// margin: -30px auto 0
	// width: 100px
	// height: 35px
	margin: -40px auto 0
	width: 115px
	height: 40px
	@media only screen and (max-width: 320px)
		margin: -30px auto 0
		width: 80px
		height: 33px
	.desktopView &
		margin: -12px auto -10px
		// width: 130px
		// height: 47px
		width: 140px
		height: 50px

.mailContent
	background: #f7f7f7
	table
		width: 100%!important
		margin-left: initial
		max-width: 1100px
		tr
			text-align: inherit
			td, th
				text-align: inherit
				border-bottom: none
			img[alt="Claroshop.com"]
				float: left

#boxNoti
	display: none!important

#inpage_container
	@media only screen and (max-width: 360px)
		width: 80%
#flix-std-inpage
	display: inline-grid

#flix_hotspots.flix_hotspotV2
	z-index: 2!important

.mtop55
	margin-top: 55px

.logoClaroshop,
.searchIcon
	font-size: 0