@import '../../../sass/_abstracts'

.wlbarra
    box-shadow: none !important
.h1MiLista
    margin-top: 10px
    padding-bottom: 25px
.boxListaDeseos
    background: white
    position: relative
    color: #4a4a4a
    padding: 10px
    .desktopVeiw &
        top: -30px
        margin-top: 0
    .is-dark-mode &
        background-color: transparent
        color: white
.contWishlisthistorial
    box-sizing: border-box
    padding: 10px
    display: grid
    grid-template-columns: repeat(5, 1fr)
    //grid-template-rows: repeat(2, 1fr)
    grid-gap: 20px
    gap: 20px
    @media only screen and (max-width: 980px)
        grid-template-columns: repeat(4, 1fr)
    @media only screen and (max-width: 860px)
        grid-template-columns: repeat(3, 1fr)
    @media only screen and (max-width: 680px)
        grid-template-columns: repeat(2, 1fr)
    @media only screen and (max-width: 480px)
        grid-template-columns: repeat(1, 1fr)
    .desktopVeiw &
        padding: 20px

    .is-dark-mode &
        background-color: transparent
    .errorProductServices
        img
            width: 100%
            height: auto
            max-width: 370px
            margin: 0px auto

.cardWishlist
    background: white
    text-align: left
    border: 1px solid rgba(0,0,0,0.2)
    padding: 10px
    box-sizing: border-box
    border-radius: 12px
    margin-bottom: 20px
    position: relative
    .is-dark-mode &
        border: 1px solid var(--color-text-calro)
        background-color: #292f35

    .boxGeneralDiscCar
        position: absolute
        box-sizing: border-box
        width: 60px
        height: 68px
        border-top-left-radius: 12px
        border-color: #096 transparent transparent #096
        border-style: solid
        border-width: 38px 33px 30px 30px
        top: 0
        left: 0
        z-index: 3//9
        .bxDisc
            font-size: 15px
            font-family: arial
            display: flex
            justify-content: left
            text-align: left
            position: relative
            left: -24px
            font-weight: 600
            top: -31px
            color: #fff
            .discoutnCard
                box-sizing: border-box
                display: inline-block
                color: #fff
                background: transparent
    .dateAdd
        box-sizing: border-box
        padding: 0 0 10px
        width: calc(100% - 60px)
        font-size: 13px
        color: #2591b4
        font-weight: 600
        .is-dark-mode &
            color: var(--color-text-placeholder-dm)
    .menuWishlist
        display: block
        position: absolute
        cursor: pointer
        top: 20px
        right: 20px
        &.active
            .menuOptionsDots
                display: block
        .iconMenuDots
            width: 20px
            height: 20px
            display: block
            &:after
                content: ""
                width: 20px
                height: 20px
                display: block
                background-image: url(/img/menuDots.svg)
                background-position: center center
                background-repeat: no-repeat
                background-size: contain
                .is-dark-mode &
                    filter: invert(1)
        .menuOptionsDots
            position: absolute
            top: 0
            right: 30px
            display: none
            border: 1px solid var(--color-gris-borde)
            padding: 5px 10px
            box-sizing: border-box
            background-color: white
            z-index: 1
            .is-dark-mode &
                background-color: #1a1a1a
                border: 1px solid var(--color-text-calro)
            li
                font-size: 14px
                padding: 5px 0
                &:hover
                    color: #2591b4

    .contentCardWLhistorial
        padding: 5px
        box-sizing: border-box
        display: block
        flex-direction: row
        .contenedorPrecios
            min-height: 10px
        .bxAgotado
            height: 50px
            display: flex
            justify-content: center
        .dataInternal
            margin: 20px auto
            width: 90%
            min-height: 150px
            .addCart
                display: flex
                margin: 5px 0
                cursor: pointer
        .contImageCard
            position: relative
            // width: 40%
            min-width: 100px
            max-width: 200px
            height: auto
            margin: auto
            min-height: 150px
            align-items: center
            display: flex
            img
                width: 100%
                min-width: 100px
                max-width: 200px
                height: auto
                object-fit: contain
                max-height: 150px
                .is-dark-mode &
                    opacity: 0.8
        .wlL
            width: 100%
            display: block
        .wlLbut
            width: 100%
            display: block //flex
            .wlRb2
                width: 90%
                margin: auto
                text-align: center
                .addCart
                    .btn
                        font-size: 12px
                        padding: 8px 10px
            .deleteItem
                // width: 80%
                color: var(--color-rojo-pale) //#2591b4
                text-align: center
                cursor: pointer
                margin-top: 20px
                margin-bottom: 10px
                display: flex
                align-items: center
                font-size: 14px
                text-decoration: underline
                justify-content: center
                .desktopView &
                    text-align: center
                .is-dark-mode &
                    color: #fff
                // &:before
                //     content: ""
                //     background-image: url(/img/icon-eliminar-blue.svg)
                //     background-size: auto
                //     background-position: center
                //     background-repeat: no-repeat
                //     width: 24px
                //     height: 24px
                //     top: -2px
                //     position: relative
        .wlR
            width: 10%
            margin: auto
        .priceProductWL
            font-size: 16px
            padding-bottom: 5px
            font-weight: 600
        .priceDiscountContent
            display: flex
            flex-direction: row
            align-items: center
            padding-bottom: 5px
        .textUnderline
            text-decoration: line-through
            color: #808080
            font-weight: 400
            margin-right: 10px
            font-size: 13px
            .mxn
                color: #808080
                text-decoration: line-through
                font-size: 13px
                font-weight: 400
            .is-dark-mode &
                color: var(--color-text-placeholder-dm)
        .discoutnCard
            box-sizing: border-box
            display: inline-block
            background: #009966
            color: #fff
            font-size: 13px
            padding: 2px 3px
            font-weight: 500
            border-radius: 6px
            // .is-dark-mode &
            //     background: #3dd026
        .stickerDigital,
        .dataInternal
            box-sizing: border-box
            padding: 0 0 0 20px
            text-align: left
            width: 100%
            .desktopView &
                padding: 0 5px

            .titleProductWL
                font-weight: 600
                font-size: 15px
                text-overflow: ellipsis
                overflow: hidden
                -webkit-line-clamp: 2
                -webkit-box-orient: vertical
                display: -webkit-box
                line-height: 1.2
                margin-bottom: 20px
    .envioGratis
        box-sizing: border-box
        color: #009966
        font-size: 11.5px
        font-weight: 600
        padding: 5px 0
        text-transform: uppercase
        .is-dark-mode &
            color: #3dd026

    .contentCardWLhistorial .messageOut
        box-sizing: border-box
        max-width: 200px
        width: 100%
        // margin: 10px 0 10px
        background: #f5a623
        color: #fff
        font-size: 12px
        display: inline-block
        padding: 5px 10px
        border-radius: 10px
        display: flex
        align-items: center
        justify-content: center
        &:after
            content: ""
            width: 20px
            height: 20px
            display: block
            background-image: url(/img/exclamacion20.png)
            background-position: center center
            background-repeat: no-repeat
            background-size: 65%
            padding-left: 5px

    .lastProducts
        box-sizing: border-box
        color: var(--color-rojo-pale)
        font-size: 14px
        padding: 5px 0

        .is-dark-mode &
            color: var(--color-rojo-dm)

    .mxn
        color: #000
        font-size: 16px
        font-weight: 700

    .moduleContFullFree
        display: flex
        flex-direction: row
        align-items: center
        .envioGratis
            margin-right: 10px
        .cyr
            padding-right: 4px
            border-right: 1px solid #ededed
            margin-right: 4px
            img
                max-width: 18px
                width: 100%
                height: auto

    .addCart
        .btn
            border: 1px solid #bbbbbb !important
        .showProduct
            display: flex
            align-items: center
            width: 83%
            margin-left: auto
            margin-right: auto
            font-size: 14px
            color: #e84563 !important
            border: 1px solid #e84563
            border-radius: 20px
            padding: 8px 15px
            width: 70%
            text-align: center
            justify-content: center
            margin-left: auto
            margin-right: auto
            margin-bottom: 10px
            text-decoration: none
            cursor: pointer
            &:after
                content: ''
                background-image: url(/img/verArt.png)
                background-size: 20px 20px
                position: relative
                background-size: auto
                box-sizing: border-box
                padding: 0
                display: block
                cursor: pointer
                width: 24px
                height: 24px
                background-repeat: no-repeat
                background-position: center
                margin: 0px 10px 0 0
                margin-left: 5px
        .addCartBtn
            display: flex
            align-items: center
            width: 83%
            margin-left: auto
            margin-right: auto
            font-size: 14px
            color: #000 !important
            border: 1px solid #bbbbbb
            border-radius: 6px
            padding: 8px 15px
            width: 70%
            text-align: center
            justify-content: center
            margin-left: auto
            margin-right: auto
            margin-bottom: 10px
            cursor: pointer
            // &:after
            //     content: ''
            //     //background-image: url(/img/bag-borde.svg)
            //     background-size: 20px 20px
            //     position: relative
            //     background-size: contain
            //     box-sizing: border-box
            //     padding: 0
            //     display: block
            //     cursor: pointer
            //     width: 22px
            //     height: 22px
            //     background-repeat: no-repeat
            //     background-position: center
            //     margin: 0px 10px 0 0
                // filter: invert(33%) sepia(25%) saturate(2890%) hue-rotate(182deg) brightness(100%) contrast(100%)
    .removeItem
        color: #000
        font-size: 14px
        display: flex
        align-items: center
        border: 1px solid #bbbbbb
        border-radius: 6px
        padding: 8px 15px
        width: 70%
        text-align: center
        justify-content: center
        margin-left: auto
        margin-right: auto
        cursor: pointer
        // &:after
        //     content: ''
        //     background-image: url(/img/icon-eliminar-red.png)
        //     background-size: 20px 20px
        //     position: relative
        //     background-size: auto//contain
        //     box-sizing: border-box
        //     padding: 0
        //     display: block
        //     cursor: pointer
        //     width: 20px
        //     height: 20px
        //     background-repeat: no-repeat
        //     background-position: center
        //     margin: 0px 0px 0 10px
            // filter: invert(33%) sepia(25%) saturate(2890%) hue-rotate(182deg) brightness(100%) contrast(100%)

.heartIcon
    background-image: url(/img/favoritos_off.png) //iconDeseos.svg
    background-size: 30px 30px//20px 20px
    position: relative
    background-size: contain
    box-sizing: border-box
    padding: 0
    display: block
    cursor: pointer
    width: 28px
    height: 28px
    background-repeat: no-repeat
    background-position: center
    margin: 0px 10px
    top: -3px
    min-width: 28px
    min-height: 28px
    .desktopView &
        margin: 0px 15px
    .is-dark-mode &
        filter: invert(1)

.cardWishlistMobileHistorial
    background: white
    text-align: left
    border: 1px solid var(--color-gris-borde)
    padding: 10px
    box-sizing: border-box
    border-radius: 12px
    margin-bottom: 20px
    position: relative
    // box-shadow: -2px 2px 13px rgba(0, 0, 0, 0.15)
    .is-dark-mode &
        border: 1px solid var(--color-text-calro)
        background-color: #292f35
    .dateAddMobile
        box-sizing: border-box
        padding: 0 0 5px
        width: 96%
        font-size: 13px
        color: #4a90e2
        font-weight: 600
        .is-dark-mode &
            color: var(--color-text-placeholder-dm)
    .contImageCardMobileHistorial
        position: relative
        width: 40%
        min-width: 100px
        max-width: 200px
        height: auto
        img
            width: 100%
            min-width: 100px
            max-width: 200px
            height: auto
            object-fit: contain
            max-height: 200px
            .is-dark-mode &
                opacity: 0.8
    .contentCardWLMobileHistorial
        padding: 15px 5px
        box-sizing: border-box
        display: flex
        flex-direction: row
        .wlLMobileHistorial
            width: 100%
            .divImgData
                display: flex
                justify-content: center
            .divBtnDelete
                display: flex
                margin-top: 5px
                .addCart
                    width: 100%
                    .showProductMobile
                        display: flex
                        align-items: center
                        width: 100%
                        margin-left: auto
                        margin-right: auto
                        font-size: 14px
                        color: var(--color-rojo-pale) !important
                        text-align: left
                        justify-content: left
                        margin-left: auto
                        margin-right: auto
                        margin-bottom: 5px
                        text-decoration: none
                        cursor: pointer
                        text-decoration: underline
                        font-weight: 600
                        @media only screen and ( max-width: 320px )
                            font-size: 13px
                        &:before
                            content: ''
                            background-image: url(/img/verArt.png)
                            background-size: 70%
                            position: relative
                            box-sizing: border-box
                            padding: 0
                            display: block
                            cursor: pointer
                            width: 24px
                            height: 24px
                            background-repeat: no-repeat
                            background-position: center
                            margin: 0px 5px 0 0
                .addCartBtnMobile
                    display: flex
                    align-items: center
                    margin-left: 16px
                    margin-right: auto
                    font-size: 14px
                    width: 60%
                    justify-content: center
                    cursor: pointer
                    font-weight: 500
                    color: #000 !important
                    border: 1px solid #bbbbbb
                    border-radius: 6px
                    padding: 3px 5px
                    @media only screen and ( max-width: 390px )
                        width: 70%
                        margin-left: 28px
                    @media only screen and ( max-width: 320px )
                        font-size: 13px
                    &::before
                        content: ''
                        background-image: url(/img/bolsa-sanborns.svg)
                        position: relative
                        background-size: contain
                        box-sizing: border-box
                        padding: 0
                        display: block
                        cursor: pointer
                        width: 22px
                        height: 22px
                        background-repeat: no-repeat
                        background-position: center
                        margin: 0px 5px 0 0
                        top: -2px
                .removeItem
                    display: flex
                    align-items: center
                    width: 40%
                    cursor: pointer
                    color: var(--color-rojo-pale)
                    &::before
                        content: ''
                        background-image: url(/img/icon-eliminar-red.png)
                        position: relative
                        background-size: contain
                        box-sizing: border-box
                        padding: 0
                        display: block
                        cursor: pointer
                        width: 20px
                        height: 20px
                        background-repeat: no-repeat
                        background-position: center
                        margin: 0px 0px 0 10px
        .priceProductWL
            font-size: 15px
            padding-bottom: 5px
            font-weight: 700
        .priceDiscountContent
            display: flex
            flex-direction: row
            align-items: center
            padding-bottom: 5px
        .textUnderline
            text-decoration: line-through
            color: #808080
            font-weight: 400
            margin-right: 10px
            font-size: 13px
            .is-dark-mode &
                color: var(--color-text-placeholder-dm)
        .discoutnCard
            box-sizing: border-box
            display: inline-block
            color: #fff
            font-size: 13px
            padding: 4px 3px
            font-weight: 700
            background: #009966
            border-radius: 8px
        .stickerDigital,
        .dataInternal
            box-sizing: border-box
            padding: 0 0 0 20px
            text-align: left
            width: 100%
            .desktopView &
                padding: 0 20px

            .titleProductWL
                font-weight: 600
                font-size: 15px
                text-overflow: ellipsis
                overflow: hidden
                -webkit-line-clamp: 2
                -webkit-box-orient: vertical
                display: -webkit-box
                line-height: 1.2
                margin-bottom: 5px
        .moduleContFullFreeMobile
            display: flex
            flex-direction: row
            align-items: center
            .envioGratis
                margin-right: 10px
                box-sizing: border-box
                color: #009966
                font-size: 11.5px
                font-weight: 600
                padding: 5px 0
                text-transform: uppercase
                @media only screen and ( max-width: 320px )
                    font-size: 10px
                    margin-right: 5px
            .fullFilment
                @media only screen and ( max-width: 320px )
                    max-width: 65px
                    width: 95%
            .cyr
                padding-right: 2px
                border-right: 1px solid #ededed
                margin-right: 4px
                padding-left: 3px
                img
                    max-width: 18px
                    width: 100%
                    height: auto

        .lastProducts
            box-sizing: border-box
            color: var(--color-rojo-pale)
            font-size: 13px
            padding: 5px 0
            .desktopView &
                font-size: 14px

        .messageOut
            box-sizing: border-box
            max-width: 186px
            width: 100%
            margin: 10px 0
            background: #f5a623
            color: #fff
            font-size: 14px
            display: inline-block
            padding: 3px 18px
            border-radius: 10px
            display: flex
            align-items: center
            @media only screen and ( max-width: 320px )
                font-size: 11px
                padding: 3px 10px
            &:after
                content: ""
                width: 20px
                height: 20px
                display: block
                background-image: url(/img/exclamacion20.png)
                background-position: center center
                background-repeat: no-repeat
                background-size: 70%
                padding-left: 5px
