$colorBlanco: #fff
$colorRojo: #c00

.contenedorQuestions
    background: $colorBlanco
    width: 90%
    margin-left: 5%
    padding-bottom: 5%
    margin-bottom: 3%
    .titleQuestions
        padding: 3%
        h3
            font-weight: 700
            font-size: 18px
    .boxQuestions
        padding-left: 5%
        text-align: left
        ul
            li
                padding-bottom: 2%
                label
                    padding-left: 2%
        .btn-red
            background: $colorRojo
            border: 1px solid $colorRojo
            width: 95%
            color: $colorBlanco
            margin-top: 5%
